<template>
  <div class="personal-aside">
    <!--  -->
    <el-row class="personal-aside-2" type="flex" style="margin-top: 10px">
      <div class="personal-aside-2-1">
        <div>
          <el-popover
            v-model="popover_visible"
            placement="right-start"
            :popover_visible="popover_visible"
          >
            <PersonalInfoCard
              :userinfo="form_info"
              @closePopover="closePopover"
            >
            </PersonalInfoCard>
            <personal-avatar
              slot="reference"
              :size="65"
              :avatar="avatar"
              :nickname="name"
              :colorid="form_info.accountId"
              style="cursor: pointer"
            ></personal-avatar>
          </el-popover>
        </div>
        <div class="personal-aside-2-1-2">
          <div>{{ name }}</div>
        </div>
      </div>
    </el-row>
    <!--  -->
    <el-row style="margin-top: 20px">
      <el-menu
        class="personal-aside-3"
        mode="vertical"
        background-color="white"
        :default-active="index_path"
        router
      >
        <el-menu-item
          v-show="role === 'SYSTEM_MANAGER'"
          index="/profile/user_manage"
        >
          <!-- <i class="el-icon-document"> </i> -->
          <i class="iconfont icon-user1"> </i>
          &nbsp;&nbsp;{{ $t("personal.menu.user") }}
        </el-menu-item>
        <el-menu-item
          v-show="role === 'SYSTEM_MANAGER'"
          index="/profile/user_group_manage"
        >
          <i class="iconfont icon-a-huaban1"> </i>
          &nbsp;&nbsp;{{ $t("personal.menu.userGroup") }}
        </el-menu-item>
        <el-menu-item index="/profile/project_manage">
          <i class="iconfont icon-projectmanger"> </i>
          &nbsp;&nbsp;{{ $t("personal.menu.project") }}
        </el-menu-item>

        <el-menu-item index="/profile/certificate_management">
          <i class="iconfont icon-a-huaban11"> </i>
          &nbsp;&nbsp;{{ $t("personalAside.SoftwareCertificateManagement") }}
        </el-menu-item>
        <el-menu-item index="/profile/third_party">
          <i class="iconfont icon-thirdParty"> </i>
          &nbsp;&nbsp;{{ $t("personal.menu.thirdParty") }}
        </el-menu-item>
        <el-menu-item
          v-show="role === 'SYSTEM_MANAGER'"
          index="/profile/open_api"
        >
          <i class="iconfont icon-openapi"> </i>
          &nbsp;&nbsp;Open API
        </el-menu-item>
        <el-menu-item
          v-show="role === 'SYSTEM_MANAGER'"
          index="/profile/other_settings"
        >
          <i class="iconfont icon-a-setting1"> </i>
          &nbsp;&nbsp;{{ $t("personal.menu.OtherSettings") }}
        </el-menu-item>
      </el-menu>
    </el-row>
    <!-- 换肤迁移 -->
    <div class="themeBox">
      <el-dropdown placement="top-start" size="medium" @command="changeTheme">
        <div id="right-header-img" class="head-img">
          <i
            class="iconfont icon-a-theme"
            style="cursor: pointer; color: #fff; font-size: 20px"
          />
        </div>
        <el-dropdown-menu slot="dropdown" class="menu-item">
          <el-dropdown-item command=""
            >{{ $t("homeTopBar.menu.blue") }}
          </el-dropdown-item>
          <el-dropdown-item command="dark"
            >{{ $t("homeTopBar.menu.dark") }}
          </el-dropdown-item>
          <el-dropdown-item command="light"
            >{{ $t("homeTopBar.menu.light") }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="version-wrap" @click="jumpToversion">{{ version }}</div>
    <el-dialog
      :title="$t('personal.edit.password')"
      :visible.sync="dialog_visible"
      :close-on-click-modal="false"
      :show-close="true"
      width="25%"
      custom-class="reset_psw"
    >
      <div class="email">{{ $t("personal.edit.info") }}: {{ email }}</div>
      <el-form ref="restpsw_form" :model="form" :rules="rules">
        <div class="verify">
          <el-form-item prop="verify_code" class="verify">
            <el-input
              v-model="form.verify_code"
              class="email"
              :placeholder="$t('personal.edit.place')"
              autocomplete="new-password"
              clearable
            >
              <template slot="prepend" style="font-size: 18px">
                <i class="el-icon-message"></i>
              </template>
            </el-input>
          </el-form-item>
          <el-button
            type="primary"
            size="large"
            class="get_verifycode"
            :disabled="disabled"
            @click="send_verifycode"
          >
            {{ getCode }}</el-button
          >
        </div>

        <el-form-item prop="new_psw">
          <el-input
            v-model="form.new_psw"
            :placeholder="$t('personal.edit.new')"
            show-password
            autocomplete="new-password"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="confirm_psw">
          <el-input
            v-model="form.confirm_psw"
            :placeholder="$t('personal.edit.confirm')"
            show-password
            autocomplete="new-password"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="plain" @click="dialog_visible = false">
          {{ $t("btn.cancelBtn") }}</el-button
        >
        <el-button type="primary" @click="save">{{
          $t("btn.saveBtn")
        }}</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :title="
        $t('personal.userManagement.table.options.editUserInfoForm.title')
      "
      width="25%"
      custom-class="reset_info"
      :visible.sync="dialoginfo_visible"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <div class="avatar">
        <div>{{ $t("personal.edit.avatar") }}</div>
        <personal-avatar
          :size="40"
          :avatar="temp_avatar"
          :nickname="name"
          :colorid="form_info.accountId"
        ></personal-avatar>
        <el-upload
          class="avatar-uploader"
          action=""
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :http-request="uploadFile"
          :on-error="handleFileError"
          :before-upload="beforeAvatarUpload"
        >
          <div size="small" class="upload_avatar">
            {{ $t("personal.edit.click") }}
          </div>
        </el-upload>
      </div>
      <el-form
        ref="restinfo_form"
        :model="form_info"
        :label-position="'top'"
        hide-required-asterisk
      >
        <el-form-item
          :label="$t('personal.userManagement.table.name')"
          prop="nickname"
          :rules="[
            {
              required: true,
              message: $t('personal.edit.info2'),
              trigger: 'blur',
            },
          ]"
        >
          <el-input v-model="form_info.nickname"> </el-input>
        </el-form-item>
        <el-form-item :label="$t('personal.userManagement.table.email')">
          <el-input
            v-model="form_info.email"
            :placeholder="$t('personal.edit.info3')"
            disabled
          >
          </el-input>
        </el-form-item>
        <el-form-item
          :label="$t('personal.userManagement.table.role')"
          style="margin-bottom: 0px"
        >
          <el-input :placeholder="$t('personal.edit.info3')" disabled>
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="plain" @click="dialoginfo_visible = false">
          {{ $t("btn.cancelBtn") }}</el-button
        >
        <el-button type="primary" @click="saveInfo"
          >{{ $t("btn.saveBtn") }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PersonalInfoCard from "./PersonalInfoCard.vue";
import PersonalAvatar from "@/components/personal";
import { request_verify_code, forget_password } from "@/network/login/index.js";
import { update_user_info } from "@/network/user/index.js";
import { mapGetters, mapMutations } from "vuex";
import { ossAuthorization } from "@/utils/oss";
import config from "@/../config/index";
// 换肤相关
import ChangeTheme from "@/assets/themes";
const packageJson = require("@/../package.json");
export default {
  inject: ["reload"],
  components: { PersonalInfoCard, PersonalAvatar },
  props: ["ismanger"],
  data() {
    let checkPsw = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("personal.edit.again")));
      } else if (value !== this.form.new_psw) {
        callback(new Error(this.$t("personal.edit.no")));
      } else {
        callback();
      }
    };
    let validatePass1 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("personalAside.checkPsw")));
      } else {
        const regExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,100}$/g;
        if (regExp.test(value)) {
          // 验证成功
          callback();
        } else {
          callback(new Error(this.$t("personalAside.checkPsw")));
        }
      }
    };
    return {
      temp_avatar: this.$store.getters.avatar,
      email: this.$store.getters.email,
      popover_visible: false,
      dialog_visible: false,
      getCode: this.$t("personal.edit.cap"),
      count: 180,
      disabled: false,
      form: {
        verify_code: "",
        new_psw: "",
        confirm_psw: "",
      },
      rules: {
        verify_code: [
          {
            required: true,
            message: this.$t("personal.edit.input"),
            trigger: "blur",
          },
        ],
        new_psw: [
          {
            required: true,
            trigger: "blur",
            validator: validatePass1,
          },
        ],
        confirm_psw: [{ validator: checkPsw, trigger: ["blur", "change"] }],
      },
      dialoginfo_visible: false,
      form_info: {
        nickname: this.$store.getters.user_info.nickname,
        roleId: [],
        email: this.$store.getters.user_info.email,
        description: "",
        avatar: "",
        accountId: this.$store.getters.user_info.accountId,
      },
      // 权限字段
      role: "",
      index_path: this.$route.path,
    };
  },
  computed: {
    ...mapGetters(["user_info", "avatar", "name", "theme"]),
    version() {
      return packageJson.version;
    },
    upload_form() {
      let obj = {};
      for (let key in this.form_info) {
        if (key != "description" && key != "avatar") {
          obj[key] = this.form_info[key];
        } else if (key == "avatar") {
          obj[key] =
            this.form_info[key] == null
              ? ""
              : this.form_info[key].indexOf(config.replace_url) !== -1
              ? this.form_info[key].split(config.replace_url)[1]
              : this.form_info[key];
        }
      }
      return obj;
    },
  },
  mounted() {
    // 换肤相关
    const theme = localStorage.getItem("_theme");
    // 加载主题 sysSkinColor
    this.changeTheme(theme);
    this.role = JSON.parse(localStorage.getItem("store")).user.roles
      ? JSON.parse(localStorage.getItem("store")).user.roles[0].name
      : "NORMAL_USER";
    Object.keys(this.form_info).forEach((item) => {
      if (item == "roleId") {
        this.form_info[item] = this.user_info.roles.map((ele) => ele.roleId);
        let description = this.user_info.roles.map((ele) => ele.description);
        this.form_info.description = description.join(",");
      } else if (item != "description") {
        this.form_info[item] = this.user_info[item];
      }
    });
  },
  methods: {
    jumpToversion() {
      const url =
        "https://ms.ytdevops.com/pubKnowledge/0b7912d6-38ef-478a-a381-f0eaefe8289c";
      window.open(url, "_blank");
    },
    closePopover(val) {
      this.popover_visible = val.popover_visible;
      if (val.type == "psw") {
        this.dialog_visible = !val.popover_visible;
        this.$nextTick(() => {
          this.$refs["restpsw_form"].resetFields();
        });
      } else if ((val.type = "info")) {
        this.dialoginfo_visible = !val.popover_visible;
        this.form_info.avatar = this.avatar;
        this.temp_avatar = this.avatar;
        this.$nextTick(() => {
          this.$refs["restinfo_form"].resetFields();
        });
      }
    },
    set_timer() {
      let countDown = setInterval(() => {
        if (this.count < 1) {
          this.disabled = false;
          this.getCode = this.$t("personal.edit.cap");
          this.count = 180;
          clearInterval(countDown);
        } else {
          this.getCode = this.count-- + this.$t("personal.edit.re");
        }
      }, 1000);
    },
    send_verifycode() {
      // 请求验证码
      request_verify_code(this.email, "RESET_PASSWORD")
        .then((res) => {
          this.$message({
            message: this.$t("addLogin.forgetPsd.message7"),
            type: "success",
            duration: 5 * 1000,
          });
          this.disabled = true;
          this.set_timer();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    save() {
      this.$refs["restpsw_form"].validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          return false;
        }
      });
    },
    submit() {
      forget_password(this.email, this.form.new_psw, this.form.verify_code)
        .then((res) => {
          this.$message.success(this.$t("personal.edit.success2"));
          this.$router.push("/login/login_password");
        })
        .catch((err) => console.log(err));
    },
    handleAvatarSuccess(res, file) {
      this.temp_avatar = res;
      this.form_info.avatar = res;
    },
    handleFileError(err) {
      console.log(err);
    },
    beforeAvatarUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 5;
      if (!isLt1M) {
        this.$message.error(this.$t("personal.edit.ava"));
      }
      return isLt1M;
    },
    //参数必须是param，才能获取到内容
    async uploadFile(param) {
      const loading = this.$loading({
        lock: true,
        text: this.$t("personal.edit.ing"),
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      // 获取上传的文件名
      let file = param.file;
      //发送请求的参数格式为FormData
      await ossAuthorization.call(this);
      this.client
        .put(
          `${this.$store.state.project.tenantId}/user_avatar/${file.name}`,
          file
        )
        .then((ress) => {
          loading.close();
          this.temp_avatar = ress.url;
          this.form_info.avatar = ress.url;
        })
        .error((r) => {
          this.$message.error(this.$t("personal.edit.error"));
          loading.close();
        });
    },
    saveInfo() {
      this.$refs["restinfo_form"].validate((valid) => {
        if (valid) {
          update_user_info(this.upload_form)
            .then((res) => {
              this.$message.success(this.$t("canvas.messages.editSuccess"));
              this.dialoginfo_visible = false;
              this.$store.commit("SET_AVATAR", this.temp_avatar);
              this.$store.commit("SET_NAME", this.upload_form.nickname);
              this.reload();
            })
            .catch((err) => console.log(err));
        } else {
          return false;
        }
      });
    },
    ...mapMutations([
      "SET_THEME", //换肤相关
    ]),
    // 换肤
    changeTheme(theme) {
      ChangeTheme.mergeCss(theme);
      this.SET_THEME(theme);
    },
  },
};
</script>

<style lang="scss" scoped>
.personal-aside {
  width: 100%;
  height: 100%;
  background: white;
  overflow: hidden;
  .el-menu {
    width: 100%;
  }

  .el-menu-item {
    color: #080808;
    text-align: left;
    font-size: 16px;
    text-indent: 20%;
    &.is-active {
      i {
        color: black !important;
      }
      color: black !important;
    }
    i {
      color: black !important;
    }
    color: black !important;
  }

  ::v-deep .reset_psw {
    .el-dialog__body {
      .email {
        font-size: 16px;
        color: #000;
        margin-bottom: 17px;
      }
      .el-form {
        .el-form-item {
          margin-bottom: 16px; /*no*/
          height: 50px;
          .el-form-item__content {
            height: 50px;
            line-height: 50px;
            width: 100%;
          }
          .el-input {
            width: 100%;
            height: 50px;
            .el-input__inner {
              height: 100%;
            }
          }
          .email {
            // width: 310px;
            .el-input__inner {
              height: 100%;
            }
          }
        }
        .verify {
          display: flex;
          align-items: center;
          justify-content: right;
          margin-bottom: 16px; /*no*/
          width: 100%;
          .el-input-group__prepend {
            font-size: 25px;
            background: white;
            padding: 0px 13px;
          }
          .get_verifycode {
            height: 50px;
          }
          .el-form-item {
            margin-bottom: 0;
          }
        }
      }
    }
    .el-dialog__footer {
      padding: 10px 40px 40px;
    }
  }

  ::v-deep .reset_info {
    // height: 434px;
    .avatar {
      display: flex;
      align-items: center;
      font-size: 16px;
      margin-bottom: 14px;
      div:first-child {
        margin-right: 32px;
      }
      .upload_avatar {
        margin-left: 16px;
        font-size: 12px;
        color: #30648f;
      }
    }
    .el-dialog__body {
      .el-form {
        .el-form-item {
          text-align: left;
          display: flex;
          .el-form-item__content {
            width: 100%;
          }
          label {
            font-size: 16px;
            width: 65px; /*no*/
            height: 35px; /*no*/
            padding: 0;
          }
          .el-input {
            height: 32px; /*no*/
            .el-input__inner {
              height: 100%;
            }
          }
        }
      }
    }
    .el-dialog__footer {
      padding: 20px 40px 40px;
    }
  }
}

.personal-aside-1 {
  display: flex;
  div {
    margin-left: 17%;
  }
}

.personal-aside-2 {
  display: flex;
  .personal-aside-2-1 {
    margin-left: 23%;
    display: flex;
    align-items: center;
    .personal-aside-2-1-2 {
      padding-left: 20px;
      font-size: 16px;
    }
  }
}
.personal-aside-3 {
  display: flex;
  flex-direction: column;
}

.my-picture {
  width: 100%;
  height: 70px;
  background: white;
  color: black;
}

.with-box-shadow {
  box-shadow: 0px 2px 4px rgba(128, 128, 128, 0.61);
}

i {
  color: rgba(0, 0, 0, 1) !important;
}
.themeBox {
  cursor: pointer;
  width: 50px;
  height: 50px;
  line-height: 50px;
  position: absolute;
  bottom: 16px;
  left: 23px;
}
.version-wrap {
  cursor: pointer;
  width: 50px;
  height: 50px;
  line-height: 50px;
  position: absolute;
  bottom: 16px;
  left: 74px;
}
.head-img {
  width: 50px;
  height: 50px;
}
.themeBox:hover {
  border-radius: 30px;
  background: rgba(204, 204, 204, 1);
}
</style>

<style scoped>
.el-menu-item.is-active {
  background: rgba(
    48.006442799999995,
    100.0017724408,
    143.004,
    0.15
  ) !important;
}
</style>
