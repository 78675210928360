<template>
  <div class="box-card">
    <el-row type="flex" style="align-items: center">
      <el-col :span="6">
        <personal-avatar
          :size="70"
          :avatar="$store.getters.avatar"
          :nickname="$store.getters.name"
          :colorid="userinfo.accountId"
        ></personal-avatar>
      </el-col>
      <el-col :span="18">
        <div>
          <span>{{ $t("personal.userManagement.table.name") }}: </span>
          <span>{{ name }}</span>
          <el-button type="text" class="edit-icon" @click="show_dialog('info')">
            <i class="el-icon-edit-outline" style="font-size: 18px"></i>
          </el-button>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" style="margin-top: 30px">
      <el-col :span="5">
        <span style="line-height: 30px">
          <i class="el-icon-message"></i>
          {{ $t("personal.userManagement.table.email") }}
        </span>
      </el-col>
      <el-col :span="10">
        <el-tooltip
          effect="dark"
          :content="email"
          :disabled="!isShowToolTip"
          placement="top"
        >
         <div class="emailBox" @mouseenter="(event) => ifShowToolTip(event)">{{ email }}</div>
        </el-tooltip>
      </el-col>
      <el-col :span="9">
        <el-button
          type="text"
          size="mini"
          style="margin-left: 25px"
          @click="show_dialog('psw')"
        >
          {{ $t("personal.edit.password") }}
        </el-button>
      </el-col>
    </el-row>
    <el-row style="margin-top: 25px">
      <span style="color: rgba(255, 87, 51, 1); cursor: pointer" @click="logout"
        ><i class="el-icon-switch-button" style="margin-right: 4px"></i
        >{{ $t("homeTopBar.menu.logout") }}
      </span>
    </el-row>
  </div>
</template>

<script>
import PersonalAvatar from "@/components/personal";
import { mapGetters } from "vuex";
import { textRange } from "@/utils/textRange";
export default {
  components: { PersonalAvatar },
  props: {
    popover_visible: {
      type: Boolean,
      default: true,
    },
    userinfo: {},
  },
  data() {
    return {
      email: this.$store.getters.email,
      isShowToolTip: true
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.replace("/login/");
      });
    },
    show_dialog(type) {
      this.$emit("closePopover", {
        popover_visible: !this.popover_visible,
        type,
      });
    },
    ifShowToolTip(event) {
      this.isShowToolTip = textRange(event.target);
    },
  },
  computed: {
    ...mapGetters(["user_info", "avatar", "name"]),
  },
};
</script>

<style lang="scss" scoped>
.box-card {
  width: 450px;
  margin: 10px auto 20px 25px; // 上边 | 右边 | 下边 | 左边
  font-size: 16px;
}

.with-box-shadow {
  box-shadow: 0px 2px 4px rgba(128, 128, 128, 0.61);
}

.edit-icon {
  margin-left: 20px;
  font-size: 20px;
}
.emailBox{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  line-height: 30px;
}
</style>
