<template>
  <div class="home">
    <!--  图标链接  -->
    <link
      rel="stylesheet"
      href="https://use.fontawesome.com/releases/v5.2.0/css/all.css"
      integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ"
      crossorigin="anonymous"
    />
    <el-header style="height: 60px">
      <home-topbar
        :is-home="false"
        :is-personal="true"
        :is-notification="false"
      ></home-topbar>
    </el-header>
    <el-container>
      <el-aside>
        <!--ismanger 是否为管理员  这个变量目前还没用 以后要用  -->
        <PersonalAside :ismanger="ismanger"></PersonalAside>
      </el-aside>
      <el-container class="main-container">
        <el-main style="margin-top: 25px; margin-left: 23px">
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import PersonalAside from "./components/PersonalAside.vue";
import HomeTopbar from "@/components/homeTopbar";
export default {
  components: {
    PersonalAside,
    HomeTopbar,
  },
  data() {
    return {
      imgsrc: "",
      ismanger: true,
    };
  },
  methods: {
    handleClick() {},
    handleClickUser() {},
    haveUnread() {},
  },
};
</script>

<style scoped>
h1 {
  cursor: default;
  color: white;
}

.fas {
  color: rgba(196, 196, 196, 1);
}

.fas:hover {
  color: #030848;
}

.el-aside {
  width: 20%;
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  background: white;
  overflow: hidden;
}

.main-container {
  background-color: rgba(240, 242, 245, 1);
  color: rgba(240, 242, 245, 1);
  overflow: hidden;
}

/* .el-dropdown-menu {
  padding: 15px;
} */

.el-header {
  display: flex;
  background: rgba(48, 100, 143, 1);
  width: 100vw;
  top: 0px;
  padding-left: 0px;
  justify-content: space-between;
}

.left-header {
  display: flex;
}

.logo-header {
  display: flex;
  padding-right: 17px;
}

.head-header {
  display: flex;
  align-items: center;
}

.right-header {
  display: flex;
}

.notice-header {
  display: flex;
  padding-top: 3.3vh;
}

/* .head-img {
  display: flex;
  padding-top: 1.56vh;
  height: 70%;
  padding-right: 20px;
} */
.left-nav {
  display: flex;
}
</style>
